body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Scrollbar Modifications */

::-webkit-scrollbar {
  position: absolute;
  width: 6px;
  height: 6px;
  margin: 0;
  cursor: pointer;
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
  background-color: #eae7e7;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--button-color);
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 1em;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #949596;
}

::-webkit-scrollbar-thumb:active {
  background-color: #949596;
}

/* Scrollbar Modifications */
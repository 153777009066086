:root {
  --MAX_WIDTH: 420px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes opacityx {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.easeload {
  opacity: 1;
  animation: opacityx normal 2s linear;
  -webkit-animation: opacityx normal 2s linear;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.MuiDrawer-paper::-webkit-scrollbar {
  display: none;
}

.liveness-detector {
  max-width: var(--MAX_WIDTH);
  width: 100%;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 2px;
}

div.liveness-detector>div>div>div>div:nth-child(4) {
  margin: 1em 0;
}

div.liveness-detector>div>div>div>div:nth-child(4)>button {
  background-color: #D3AC67;
  color: #ffffff;
  border: 2px solid #D3AC67;
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
  width: calc(var(--MAX_WIDTH) - 4px);
  margin: auto;
  position: fixed;
  bottom: 4px;
  left: auto;
  right: auto;

}